.service-section {
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  gap: 20px;
  flex-direction: column;
  padding: 10px;
}

.service-header h3 {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

.service-header span {
  display: block;
  width: 100px;
  line-height: 20px;
  border-bottom: #000 1px solid;
  margin: 0 auto;
  height: auto;
}

.service-header h4 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #B33030;
}

.service-content {
  /* width: 100%; */
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
}

.service-item {
  background-color: #fff;
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
  gap: 10px;
  text-align: center;
}

.service-item img {
  width: 100%;
  height: 250px;
  border-radius: 15px;
}

.service-item p {
  width: 100%;
  padding: 10px;
}

@media screen and (max-width: 960px) {
  .service-content {
    align-items: center;
    flex-direction: column;
  }

  .service-section {
    padding: 10px;
  }

  .service-item {
    width: 100%;
    max-width: none;
  }
}