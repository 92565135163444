/* product */
.product-view {
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  gap: 20px;
  /* justify-content: center; */
  /* align-items: center; */
  margin-top: 20px;
}

.product-view-grid {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.product-view-item {
  width: 100%;
  display: flex;
  gap: 30px;
}

.product-view-item h3 {
  font-size: 24px;
}

.product-view-content {
  flex: 2;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.product-view-content button {
  max-width: 200px;
}

.product-pictures {
  flex: 1;
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: column;
  position: relative;
}

.product-pictures-big {
  width: 520px;
  height: 400px;
  text-align: center;
  border: 1px solid #999;
  border-radius: 15px;
}

.product-pictures-big img {
  height: 95%;
}

.product-pictures-small {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  /* display: grid; */
  /* grid-template-columns: repeat(auto-fit, minmax(25%, 1fr)); */
}

.product-pictures-small div {
  width: 120px;
  height: 80px;
  text-align: center;
  border: 1px solid #000;
  border-radius: 15px;
}

.product-pictures-small img {
  /* border: none; */
  height: 75px;
}

.product-pictures-small div:hover {
  border: 2px solid #B33030;
}

.product-pictures-small .disabled {
  border: none;
  cursor: default;
}

.product-pictures-small .disabled:hover {
  border: none;
}

.product-pictures-small .active {
  border: 2px solid #B33030;
  border-radius: 15px;
}

.product-view-desc {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  /* padding: 10px; */
}

.product-view-desc p {
  line-height: 25px;
}

.call-now {
  width: 200px;
}

.phone-sms {
  background-color: #e5e5e5;
  color: #000;
}

.product-tel-sms {
  display: flex;
  flex-direction: column;
  gap: 10px;
  /* align-items: center; */
  /* justify-content: center; */
}

.text-now {
  width: 200px;
  display: none;
}

.product-detail-note {
  background-color: #fafafa;
  padding: 10px;
  border-radius: 15px;
  border: 1px dashed #666;
}

.rating-wrap {
  display: flex;
  gap: 10px;
  align-items: center;
}

@media screen and (max-width: 960px) {

  .text-now {
    display: block;
  }

  .product-view-item {
    flex-direction: column;
  }

  .product-view-item {
    padding: 10px;
  }

  .product-pictures-big {
    width: 100%;
  }

  .product-pictures-small div {
    flex: 1;
    width: 100%;
  }

  .product-tel-sms {
    flex-direction: column;
  }

  /* .product-pictures-small img {
    width: 100%;
  } */
}