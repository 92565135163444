.backToTop {
    position: fixed;
    bottom: 40px;
    right: 20px;
    width: 60px;
    height: 60px;
    background-color: #19282F;
    color: #fff;
    cursor: pointer;
    font-size: 18px;
    border-radius: 50px;
    border: #aaa 2px solid;
    z-index: 99999;
}

.backToTop:hover {
    background-color: #fff;
    color: #000;
}