/* product */
.product-content {
  margin: 0 auto;
  max-width: 1280px;
  display: flex;
  gap: 20px;
  justify-content: center;
  /* align-items: center; */
  margin-top: 20px;
  padding: 10px;
}

.product-list {
  flex: 3;
  display: flex;
  gap: 20px;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
}

.column-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 24px;
  line-height: 40px;
  height: 40px;
  border-bottom: #999 1px solid;
}

.column-header h1 {
  font-size: 18px;
  justify-content: flex-start;
  color: #B33030;
}

.product-grid {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.commom-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #999;
  border-radius: 5px;
  margin-bottom: 10px;
}

.delivery-item {
  margin: 0 auto;
  max-width: 1200px;
  display: flex;
  gap: 20px;
  padding: 10px;
  /* justify-content: center; */
  /* align-items: center; */
  margin-top: 20px;
}

.mobile-filter {
  display: none;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  background-color: #19282F;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
}


@media screen and (max-width: 960px) {

  .mobile-filter {
    display: flex;
  }

  .product-content {
    flex-direction: column;
  }

  /* .product-list {
    padding: 10px;
  } */
  /* .header-total {
    display: none;
  } */

  .column-header {
    font-size: 15px;
    flex-direction: column;
    /* line-height: 80px; */
    height: 60px;
    line-height: 30px;
  }
}