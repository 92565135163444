.new-item-card {
    width: 100%;
    display: flex;
    /* justify-content: space-between; */
    flex-direction: column;
    gap: 20px;
}

.new-item.new-item-card a,
a:hover {
    color: black;
}

.new-item-card img {
    width: 100%;
    height: 250px;
    border-radius: 15px;
}

@media screen and (max-width: 960px) {
    .new-item {
        width: 100%;
        max-width: 100%;
        flex-direction: column;
    }
}