/* new */
.new-content {
  margin: 0 auto;
  max-width: 1280px;
  display: flex;
  gap: 50px;
  justify-content: center;
  padding: 30px;
}

.new-list {
  flex: 3;
  display: flex;
  gap: 20px;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
}

.new-content-list {
  display: flex;
  gap: 30px;
  flex-direction: column;
}

.new-content-list-new {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  /* grid-template-rows: repeat(2, 1fr); */
}

@media screen and (max-width: 960px) {
  .new-content {
    flex-direction: column;
    padding: 10px;
  }

  /* .new-list {
    padding: 10px;
  } */
  .new-content-list {
    grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
  }

  .new-content-list-new {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
  }
}