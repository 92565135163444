.policy-section {
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.policy-section a {
    color: black;
}

.policy-list {
    display: flex;
    gap: 100px;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
}

.policy-content {
    width: 100%;
    font-size: 18px;

}

.policy-content h3 {
    font-size: 30px;
    color: #000;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;
}

.policy-content span {
    display: block;
    width: 100px;
    line-height: 20px;
    border-bottom: #000 1px solid;
    margin: 0 auto;
    height: auto;
}

.policy-item {
    width: 450px;
    height: 300px;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: right;
    align-items: flex-end;
    cursor: pointer;
    padding-bottom: 30px;
    border-radius: 20px;
}

.policy-item .policy-title {
    justify-content: flex-end;
    background-color: #fff;
    width: 200px;
    padding: 0.75rem;
    font-size: 20px;
}

.policy-desc {
    display: none;
    padding: 30px;
    width: 100%;
    color: #fff;
    flex-direction: column;
    gap: 10px;
    font-size: 18px;
}

.policy-desc h4 {
    font-size: 30px;
    border-bottom: rgb(250, 217, 0) 3px solid;
    width: 250px;
    line-height: 60px;
}

.policy-desc p {
    word-break: break-all;
}

.policy-item-refund {
    background-image: url('/public/images/policy-refund.webp');
}

.policy-item-refund:hover {
    align-items: flex-start;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
        url('/public/images/policy-refund.webp');
}

.policy-item-store {
    background-image: url('/public/images/policy-store.webp');
}

.policy-item-store:hover {
    align-items: flex-start;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
        url('/public/images/policy-store.webp');
}

.policy-item-privacy {
    background-image: url('/public/images/policy-privacy.webp');
}

.policy-item-pricing {
    background-image: url('/public/images/policy-pricing.webp');
}

.policy-item-pricing:hover {
    align-items: flex-start;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
        url('/public/images/policy-pricing.webp');
}

.policy-item-privacy:hover {
    align-items: flex-start;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
        url('/public/images/policy-privacy.webp');
}

.policy-item:hover .policy-desc {
    display: flex;
}

.policy-item:hover .policy-title {
    display: none;
}

.policy-detail {
    display: flex;
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;
    gap: 20px;
    padding: 20px;
}

.policy-detail a {
    color: black;
}

.policy-side {
    width: 500px;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.ql-align-right {
    text-align: right;
}

.ql-align-center {
    text-align: center;
}

.ql-align-left {
    text-align: left;
}

@media screen and (max-width: 960px) {
    .policy-section {
        width: 100%;
        padding: 10px;
    }

    .policy-list {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px;
        grid-template-columns: none;
    }

    .policy-item {
        width: 100%;
    }

    .policy-detail {
        flex-direction: column;
        width: 100%;
    }

    .policy-side {
        width: 100%;
    }
}