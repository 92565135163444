.tire-search-list {
    display: flex;
    gap: 10px;
}

.tire-search-list-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tire-search-list-item select {
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.tire-search-list-item h4 {
    font-size: 13px;
}

.tire-search-list-mobile {
    display: none;
}

.tire-search-list-desktop {
    display: flex;
}

.tire-search-tab {
    display: flex;
    /* justify-content: space-between; */
}

.tire-search-btns {
    display: flex;
    gap: 10px;
}

@media screen and (max-width: 960px) {

    .tire-search-list-mobile {
        display: flex;
    }

    .tire-search-list-desktop {
        display: none;
    }
}