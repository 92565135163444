.new-item {
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  gap: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.new-item a,
a:hover {
  color: black;
}

.new-item img {
  width: 350px;
  height: 250px;
  border-radius: 15px;
  border: 1px solid #ccc;
}

.new-item-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 10px;
}

.new-item-content h3 {
  font-weight: 20px;
  line-height: 20px;
}

.new-item-content p.desc {
  line-height: 20px;
}

.new-item-content p.date {
  line-height: 20px;
  font-weight: bold;
}

.new-left-black {
  border-left: black 5px solid;
}

.new-left-red {
  border-left: #B33030 5px solid;
}

.new-item .new-read-more {
  float: right;
}

@media screen and (max-width: 960px) {
  .new-item {
    width: 100%;
    max-width: 100%;
    flex-direction: column;
  }
}