.contact-wrapper {
    margin: 0 auto;
    max-width: 1280px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.contact-left {
    padding: 50px;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contact-content {
    flex: 3;
    padding: 20px;
}

.contact-left-item {
    padding: 15px;
    border: #ccc 1px solid;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.contact-content>h3 {
    width: 300px;
    font-size: 30px;
    line-height: 60px;
    border-bottom: 3px solid #000;
    margin-bottom: 20px;
}

.contact-content>p {
    line-height: 30px;
    font-size: 16px;
    width: 80%;
}

@media screen and (max-width: 960px) {
    .contact-wrapper {
        flex-direction: column;
    }
}