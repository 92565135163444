.product-side {
  padding: 20px;
  flex: 1;
  background-color: #fff;
  box-shadow: #bbb 0px 0px 10px;
  /* max-height: 500px; */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.common-select {
  float: right;
  border: none;
  padding: 5px;
  background-color: #19282F;
  cursor: pointer;
  color: #fff;
  font-size: 14px;
  border-radius: 5px;
}

.input-radio-checkbox {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.filter-item {
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
}

.filter-item span {
  float: right;
}

.filter-item label {
  cursor: pointer;
}

.filter-types {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.type-title {
  font-size: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.type-child {
  padding-left: 20px;
}

.tire-search-list-side {
  flex-direction: column;
  gap: 10px;
}

.tire-search-list-side h4 {
  font-size: 14px;
}

.tire-search-button {
  padding: 10px;
}

.tire-search-button-clear {
  background-color: #fff;
  color: #19282F;
}

@media screen and (max-width: 960px) {
  .product-side {
    margin: 10px;
  }
}