.topline-wrapper {
    background-color: #19282F;
    width: 100%;
    color: #fff;
    padding: .75rem;
}

.topline-content {
    margin: 0 auto;
    max-width: 1280px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.topline-left {
    display: flex;
    align-items: center;
    gap: 20px;
}

.topline-item {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 12px;
}

.topline-item h3 {
    margin: 0;
    padding: 0;
}

.topline-right {
    justify-content: flex-end;
    display: flex;
    gap: 15px;
}

.topline-status {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
}

.topline-location {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
}

.topline-opentime {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    cursor: pointer;
}

@media (max-width: 960px) {

    .topline-wrapper {
        padding-left: 10px;
        padding-right: 10px;
    }

    .topline-content {
        width: 100%;
        gap: 10px;
        flex-direction: column;
    }

    .topline-left {
        gap: 10px;
        justify-content: center;
        align-items: center;
    }

    .topline-item {
        gap: 0;
    }

    .topline-item h3 {
        font-size: 12px;
    }

    .topline-opentime {
        display: none;
    }

    .topline-left {
        width: 100%;
        justify-content: space-between;
        display: none;
    }

    .topline-right {
        width: 100%;
        justify-content: space-between;
    }

    .topline-location {
        justify-content: flex-start;
    }

    .topline-status {
        justify-content: flex-end;
    }

    /* .topline-left {
        display: none;
    } */
}