.navigation {
  margin: 0 auto;
  max-width: 1280px;
  display: flex;
  padding: 10px;
}

.navigation ul {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  /* flex-wrap: wrap; */
  font-size: 18px;
  gap: 10px;
}

@media screen and (max-width: 960px) {
  .navigation ul {
    flex-wrap: wrap;
  }
}