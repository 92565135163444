.cart-side {
  padding: 20px;
  flex: 1;
  background-color: #fff;
  box-shadow: #bbb 0px 0px 10px;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cart-side-item {
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 20px;
}

.cart-side-item span {
  float: right;
}

.cart-side-item label {
  cursor: pointer;
}

.cart-side-line {
  display: flex;
  flex-direction: column;
  gap: 10px;
}


@media screen and (max-width: 960px) {
  .cart-side {
    margin: 10px;
  }
}