.wheels-section {
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;
    display: flex;
    gap: 20px;
    flex-direction: column;
}

.wheels-content {
    width: 100%;
    font-size: 18px;
}

.wheels-content h3 {
    font-size: 30px;
    color: #000;
    margin-bottom: 20px;
    font-weight: bold;
    text-align: center;
}

.wheels-content span {
    display: block;
    width: 100px;
    line-height: 20px;
    border-bottom: #000 1px solid;
    margin: 0 auto;
    height: auto;
}

.wheels-list {
    display: flex;
    gap: 50px;
    align-items: center;
    justify-content: center;
}

.wheels-list-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    border: #ccc solid 1px;
    padding: 20px;
}

.wheels-list-item img {
    width: 100%;
    max-height: 400px;
}

.wheels-list-desc ul li {
    list-style-type: inherit;
}

.wheels-list-price {
    font-size: 30px;
    font-weight: 500;
    color: #000;
    margin-top: 20px;
}

@media screen and (max-width: 960px) {
    .wheels-list {
        flex-direction: column;
        gap: 20px;
    }
}