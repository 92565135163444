.product-section {
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  gap: 20px;
  flex-direction: column;
  padding: 10px;
}

.product-header {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
}

.product-header h3 {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}

.product-header span {
  display: block;
  width: 100px;
  line-height: 20px;
  border-bottom: #000 1px solid;
  margin: 0 auto;
  height: auto;
}

.product-section a {
  font-size: 18px;
  font-weight: 300;
  color: #000;
  cursor: pointer;
}

.product-section a:hover {
  color: #B33030;
}

.tire-types-section {
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  gap: 20px;
  flex-direction: column;
}

.tire-types-content {
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  /* justify-content: space-between; */
}

.tire-types-content-item {
  background-color: #fff;
  width: 100%;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  gap: 10px;
  text-align: center;
}

.tire-types-content-item img {
  width: 100%;
  height: 200px;
  border-radius: 15px;
}

.tire-types-content-item h3 {
  text-align: center;
}

.tire-types-content-item p {
  width: 100%;
  padding: 10px;
}

@media screen and (max-width: 960px) {
  .product-section {
    padding: 10px;
  }

  .tire-types-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .tire-types-content-item img {
    width: 100%;
    height: 200px;
    border-radius: 15px;
  }

}