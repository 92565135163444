.product-item {
  width: 100%;
  display: flex;
  gap: 30px;
  /* max-width: 500px; */
  padding-bottom: 10px;
  border-bottom: #ccc 1px solid;
  justify-content: space-between;
  flex-direction: row;
}


.product-item-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  flex: 1;
}

.product-item-content a {
  color: black;
}

.product-item .product-item-content a:hover {
  color: #B33030;
}

.product-item-content h3 {
  font-size: 24px;
}

.product-item .product-item-content button {
  max-width: 200px;
}

/* .product-item .item-img img {
  width: 100%;
} */

.item-img {
  width: 350px;
  height: 250px;
  text-align: center;
  position: relative;
}

/* 显示在右上角 */
.item-img-3pms {
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  top: 10px;
  right: 10px;
}

.item-img .item-img-product {
  height: 240px;
}

.view-detail {
  width: 200px;
}

.status_on_sale {
  color: #fff;
  background-color: #87d068;
  display: block;
  text-align: center;
  padding: 0.2rem;
  width: 80px;
  border-radius: 5px;
}

.status_on_clear {
  color: #fff;
  background-color: #f50;
  display: block;
  text-align: center;
  padding: 0.2rem;
  width: 120px;
  border-radius: 5px;
}

.status_premium {
  color: #fff;
  background-color: #000;
  display: block;
  text-align: center;
  padding: 0.2rem;
  width: 80px;
  border-radius: 5px;
}

.product-price {
  display: flex;
  gap: 20px;
}

.product-price-partner {
  color: #38b900;
}

.product-price-vip {
  color: #B33030;
}

.product-item-btns {
  display: flex;
  gap: 20px;
}

@media screen and (max-width: 960px) {
  .product-item {
    flex-direction: column;
  }

  .item-img {
    width: 100%;
  }

  .product-item-btns {
    flex-direction: column;
    gap: 10px;
  }
}