.new-detail {
  margin: 0 auto;
  max-width: 1280px;
  display: flex;
  gap: 50px;
  justify-content: center;
  /* align-items: center; */
  padding: 30px;
}

.new-detail-content {
  flex: 3;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.new-detail-content .image img {
  width: 100%;
  max-height: 600px;
}

.new-detail-content .content img {
  max-width: 800px;
}

.new-detail-content .date {
  font-weight: bold;
}

.new-detail-side {
  flex: 1;
  max-width: 350px;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.new-view-author {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  gap: 20px;
}

.new-view-author h3 {
  font-size: 28px;
}

.new-view-author img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  /* background-color: black; */
  box-shadow: #bbb 0px 0px 10px;
}

.author-content {
  display: flex;
  gap: 20px;
}

.author-desc {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.author-desc h4 {
  font-size: 18px;
}

.author-desc button {
  margin-left: 0;
}

.new-view-related {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media screen and (max-width: 960px) {
  .new-detail {
    flex-direction: column;
    padding: 10px;
  }

  .new-detail-side {
    width: 100%;
    max-width: 100%;
  }
}