.banner-wrapper {
  margin: 0 auto;
  width: 1280px;
}

.banner-section {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  background-size: cover;
  /* background-position: center; */
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #fff;
  gap: 20px;
  border: #ccc 1px solid;
  border-radius: 20px;
}

.banner-section h3 {
  font-size: 50px;
  font-weight: bold;
}

.banner-section h4 {
  font-size: 30px;
  font-weight: 300;
}


@media screen and (max-width: 960px) {
  .banner-wrapper {
    padding: 10px;
    width: 100%;
  }

  .banner-section {
    margin: 0;
    padding: 0;
    background-position: center;
    background-repeat: no-repeat;
  }
}