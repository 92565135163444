.home-dark-section {
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #000;
    color: #fff;
}

.home-dark-section-content {
    margin: 0 auto;
    max-width: 1024px;
    /* background-image: url('/public/images/banner-contact.webp'); */
    /* background-image: url('/public/images/donation.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    padding: 20px;
}

.home-dark-section-content img {
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
    border-radius: 20px;
}

.home-dark-section-content h2 {
    display: none;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
}

.home-shipping-section {
    /* margin-top: 20px; */
    /* margin-bottom: 20px; */
    /* background-color: #000; */
    color: #fff;
}

.home-shipping-section-content {
    margin: 0 auto;
    max-width: 1280px;
    /* background-image: url('/public/images/banner-contact.webp'); */
    /* background-image: url('/public/images/donation.webp');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
    height: 400px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    /* padding: 20px; */
}

.home-shipping-section-content img {
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
    border-radius: 20px;
}

.home-shipping-section-content h2 {
    display: none;
    font-size: 30px;
    font-weight: 700;
    text-align: center;
}

.home-seo-section {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    background-color: #f7f7f7;
    color: #000;
}

.home-seo-section-wrapper {
    margin: 0 auto;
    width: 1280px;
    display: flex;
    flex-direction: column;
}

.dots-class li {
    background-color: #000;
}

.home-seo-section-slide {
    width: 100%;
    /* height: 400px; */
}

.home-seo-section-content {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 20px;
    /* height: 400px; */
}

.home-seo-section-desc {
    flex: 1;
}

.home-seo-section-desc h3 {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 20px;
}

.home-seo-section-desc p {
    font-size: 16px;
    font-weight: 350;
}

.home-seo-section-img {
    width: 400px;
    text-align: center;
}

.home-seo-section-img img {
    text-align: center;
    max-width: 350px;
    border-radius: 20px;
}

.home-seo-section-reddeer {
    margin-right: 20px;
    border-radius: 20px;
}

.install-app {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}


@media screen and (max-width: 960px) {
    .home-dark-section-content {
        width: 100%;
        height: 200px;
    }

    .home-dark-section-content img {
        object-fit: fill;
    }

    .home-shipping-section-content {
        width: 100%;
        height: 250px;
        padding: 10px;
    }

    .home-shipping-section-content img {
        object-fit: fill;
        height: 200px;
    }

    .home-seo-section-wrapper {
        width: 100%;
    }

    .home-seo-section-content {
        width: 100%;
        max-width: 100%;
        flex-direction: column;
        gap: 20px;
    }

    .home-seo-section-content img {
        display: none;
    }

    .home-seo-section-reddeer {
        width: 100%;
    }
}