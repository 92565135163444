.comment-wrap {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.comment-wrap h3 {
    font-size: 1.5rem;
    font-weight: 500;
    color: #333;
    margin-bottom: 10px;
}

.comment-wrap a {
    font-size: 1.2rem;
    font-weight: 500;
    color: #333;
    font-weight: 300;
}

.comment-section {
    margin: 0 auto;
    width: 1280px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    gap: 20px;
}

.comment-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    border-radius: 15px;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    box-shadow: #e6e6e6 0px 0px 5px;
}

.comment-item:hover {
    background-color: #f5f5f5;
    cursor: pointer;
}

@media screen and (max-width: 960px) {
    .comment-section {
        width: 100%;
        padding: 10px;
        flex-direction: column;
        gap: 10px;
    }
}