/* new */
.new-content {
    margin: 0 auto;
    max-width: 1280px;
    display: flex;
    gap: 50px;
    justify-content: center;
    padding: 30px;
  }
  
  .new-list {
    flex: 3;
    display: flex;
    gap: 20px;
    /* justify-content: center; */
    /* align-items: center; */
    flex-direction: column;
  }
  
  .new-content-list {
    display: flex;
    gap: 30px;
    flex-direction: column;
  }
  
  @media screen and (max-width: 960px) {
    .new-content {
      flex-direction: column;
      padding: 10px;
    }
  
    /* .new-list {
      padding: 10px;
    } */
    .new-content-list {
      grid-template-columns: repeat(auto-fit, minmax(100%, 1fr));
    }
  }