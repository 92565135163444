.modal {
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 1000;
}

.modal-main {
  position:fixed;
  background: white;
  width: 60%;
  height: 80%;
  top:50%;
  left:50%;
  padding: 20px;
  transform: translate(-50%,-50%);
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.modal-title {
  text-align: center;
}

.modal-content {
  height: 630px;
  overflow-y: scroll;
}

.modal-btns {
  justify-content: flex-end;
  /* position: absolute; */
  /* bottom: 15px; */
  /* left: 35%; */
  text-align: center;
  display: flex;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.btn-agree {
  width: 100px;
}

@media screen and (max-width: 960px) {
  .modal-main {
    width: 90%;
  }
}