.partner-brands-section {
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 1280px;
  gap: 20px;
  flex-direction: column;
  padding: 50px;
}

.partner-brands-section .partner-header h3 {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 20px;
}

.partner-brands-section .partner-header h4 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #B33030;
}

.partner-brands-section a {
  font-size: 22px;
  font-weight: bold;
  color: #000;
  cursor: pointer;
}

.partner-brands-section a:hover {
  color: #B33030;
}

.partner-top-images {
  display: grid;
  gap: 50px;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
}

.partner-top-image {
  /* width: 250px; */
  text-align: center;
}

.partner-top-image-item {
  width: 100%;
  text-align: center;
  border-radius: 20px;
  border: #ccc 1px solid;
  padding: 20px;
}

.partner-top-title {
  text-align: center;
}

.partner-top-desc {
  text-align: left;
}

@media screen and (max-width: 960px) {
  .partner-brands-section {
    padding: 10px;
  }

  /* .product-content-images img {
    width: 100%;
  } */
}