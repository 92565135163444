.login-section {
  margin: 0 auto;
  width: 100%;
  display: flex;
  max-width: 1280px;
  gap: 40px;
  padding: 10px;
  /* justify-content: center; */
  /* align-items: center; */
  padding-top: 20px;
  padding-bottom: 50px;
}

.login-form {
  flex: 1;
  width: 100%;
  background-color: #fff;
  padding: 50px;
  box-shadow: #e5e5e5 0px 0px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.login-form label {
  font-weight: bold;
}

.login-form .form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.login-form .form-group label {
  font-weight: bold;
}

.register-form {
  flex: 1;
  width: 100%;
  background-color: #fff;
  padding: 50px;
  font-size: 18px;
  box-shadow: #e5e5e5 0px 0px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.register-form label {
  font-weight: bold;
}

.login-img {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.login-img img {
  width: 100%;
  border-radius: 25px;
}

.login-img a {
  color: #000;
  text-decoration: none;
}

.login-img a:hover {
  color: #B33030;
}

.login-button {
  background-color: #fff;
  color: #B33030;
  font-weight: bold;
  padding: 10px;
  cursor: pointer;
  border: #B33030 solid 2px;
}

.input-group {
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 20px;
}

.agreement input {
  width: 20px;
  height: 20px;
}

.agreement {
  cursor: pointer;
  font-size: 16px;
  line-height: 16px;
}

.common-input {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 5px;
  resize: vertical;
  font-size: 18px;
}

.result-error {
  display: flex;
  background-color: #f1c40f;
  border-radius: 5px;
  color: #fff;
  padding: 15px;
  margin-left: 15px;
  margin-right: 15px;
}

.result-success {
  display: flex;
  background-color: #23b89a;
  border-radius: 5px;
  color: #fff;
  padding: 15px;
  margin-left: 15px;
  margin-right: 15px;
}

.btn-readit {
  padding: 10px;
  border: none;
  cursor: pointer;
  font-weight: bold;
  border-radius: 5px;
}

@media screen and (max-width: 960px) {
  .login-section {
    flex-direction: column;
  }

  .login-form,
  .register-form {
    padding: 10px;
  }
}