header {
  background-color: #fff;
  width: 100%;
  margin: 0 auto;
  /* position: fixed; */
  top: 0;
  z-index: 99;
  -webkit-box-shadow: 0 4px 4px -4px #999;
  -moz-box-shadow: 0 4px 4px -4px #999;
  box-shadow: 0 4px 4px -4px #999;
}



.navbar {
  margin: 0 auto;
  max-width: 1280px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav-logo {
  margin: 1rem;
}

.nav-links {
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 20px;
  gap: 15px;
}

.nav-links a {
  padding: .5rem .75rem;
  color: #000;
  text-decoration: none;
}

.nav-links .active {
  color: #fff;
  background-color: #19282F;
}

.nav-links a:hover {
  color: #fff;
  color: #19282F;
}

.nav-links .active:hover {
  color: #fff;
}

.nav-links-item {
  cursor: pointer;
  font-size: large;
}

.nav-menu {
  position: absolute;
  top: 5rem;
  right: 1rem;
  flex-direction: column;
  justify-content: space-between;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: none;
}

.nav-right {
  justify-content: space-between;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.nav-divider {
  height: 100%;
  width: 1px;
  margin-left: 20px;
  margin-right: 20px;
  border-right: #ccc 1px solid;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}

@media (max-width: 960px) {

  .navbar {
    width: 100%;
    flex-direction: column;
  }

  .nav-menu {
    display: flex;
  }

  .nav-links {
    flex-direction: column;
  }

  .topline-content {
    width: 100%;
    gap: 10px;

  }

  .topline-left {
    justify-content: center;
    align-items: center;
  }

  .topline-right {
    display: none;
  }

  .nav-right {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}