.steps-wrapper {
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.steps-content {
    width: 100%;
    border-radius: 15px;
    border: #ccc 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
}

.steps-logo {
    flex: 1;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
}

.steps-logo h3 {
    font-size: 30px;
}

.steps-item {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 960px) {
    .steps-wrapper {
        padding: 10px;
    }

    .steps-content {
        width: 100%;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
    }

    .steps-logo {
        padding-left: 100px;
    }

    .steps-item {
        padding-left: 50px;
    }
}