.slideshow-container {
    max-width: 1280px;
    position: relative;
    margin: auto;
    min-height: 600px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    border-radius: 20px;
}

.home-hero-section {
    margin: 0 auto;
    width: 1280px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.home-hero-section-first {
    width: 100%;
    min-height: 500px;
    background-image: url('/public/images/hero1.webp');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    align-items: center;
    border-radius: 20px;
    justify-content: space-between;
    /* padding: 100px; */
    /* gap: 100px; */
}

.slideshow-content {
    /* position: relative; */
    width: 100%;
    height: 550px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 50px;
    padding-right: 100px;
}

.slideshow-content-mobile {
    /* position: relative; */
    width: 100%;
    height: 600px;
    display: flex;
    align-items: flex-end;
    padding-bottom: 45px;
}

.slideshow-content p {
    display: none;
}

.slideshow-content-desc {
    width: 100%;
    max-width: 350px;
    font-size: 2rem;
    font-weight: 400;
    color: #fff;
    text-shadow: #000 0px 0px 5px;
    background-color: rgb(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
    margin-top: 20px;
}

.slideshow-content-desc:hover {
    cursor: pointer;
    color: #fff;
    background-color: rgb(0, 0, 0, 0.7);
}

.hero-section-button {
    justify-content: flex-end;
    /* flex: 1; */
    height: 50px;
    text-align: center;
    background-color: #B33030;
}

.tire-search-wrapper {
    /* width: 320px; */
    width: 500px;
    height: 220px;
    /* position: relative;
    top: 150px;
    left: 100px; */
    /* flex: 1; */
    display: flex;
    flex-direction: column;
    padding-top: 50px;
}

.tire-search {
    width: 100%;
    background-color: #fff;
    padding: 20px;
    border-radius: 0 0 20px 20px;
    box-shadow: #e6e6e6 0px 0px 5px;
    flex: 1;
    /* justify-content: space-between; */
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tire-search-title {
    text-align: center;
    padding: 5px 10px;
    width: 150px;
    font-size: 16px;
    font-weight: 350;
    background-color: #19282F;
    color: #fff;
    border-radius: 10px 10px 0 0;
    cursor: pointer;
}

.tire-search-title-active {
    background-color: #B33030;
    color: #fff;
}

.tire-search-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tire-search-content h3 {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    /* justify-content: space-between; */
}

.tire-search-content h3 span {
    display: block;
    color: #fff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: #000;
    text-align: center;
    line-height: 20px;
}

.tire-search-item {
    font-size: 20px;
    background-color: #f2f6f9;
    padding: 5px 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    border-radius: 10px;
    cursor: pointer;
}

.tire-search-item-left {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 300;
    font-size: 14px;
    white-space: nowrap;
}

.tire-search-item-right {
    justify-content: flex-end;
}

/* .home-hero-description {
    color: #fff;
    justify-content: flex-end;
    font-size: 30px;
    flex: 2;
    background-color: rgb(0, 0, 0, 0.5);
    ;
    padding: 15px;
    border-radius: 15px;
}

.home-hero-description h1,
h2 {
    font-size: 40px;
    font-weight: bold;
    margin: 0;
    padding: 0;
    text-shadow: #000 0px 0px 5px;
}

.home-hero-description p {
    font-weight: 400;
    margin: 0;
    padding: 0;
    text-shadow: #000 0px 0px 5px;
} */

.tire-types-wrapper {
    display: grid;
    grid-template-columns: repeat(3, 300px);
    gap: 20px
}

.tire-types-item {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    gap: 10px;
    text-align: center;
    border: #e6e6e6 1px solid;
    border-radius: 0 0 10px 10px;
    /* padding: 5px; */
    padding-bottom: 10px;
}

.tire-types-item:hover {
    cursor: pointer;
    background-color: #efefef;
}

.tire-types-item img {
    width: 100%;
    height: 200px;
}

.tire-brands-item {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    border: #ccc 1px solid;
    flex-direction: column;
    padding: 20px;
}

.tire-brands-item img {
    width: 100%;
}

/* Fading animation */
.fade {
    animation-name: fade;
    animation-duration: 1.5s;
}

/* Next & previous buttons */
.slideshow-prev,
.slideshow-next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    width: auto;
    margin-top: -22px;
    padding: 16px;
    color: white;
    font-weight: bold;
    font-size: 18px;
    transition: 0.6s ease;
    border-radius: 0 3px 3px 0;
    user-select: none;
}

/* Position the "next button" to the right */
.slideshow-next {
    right: 0;
    border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.slideshow-prev:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.slideshow-next:hover {
    background-color: rgba(0, 0, 0, 0.8);
}

.slideshow-dot-content {
    position: absolute;
    bottom: 20px;
    left: 45%;
    display: flex;
    gap: 10px;
}

.slideshow-dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #717171;
    border-radius: 50%;
    display: inline-block;
    transition: background-color 0.6s ease;
}

.active,
.slideshow-dot:hover {
    background-color: #bbb;
}



@keyframes fade {
    from {
        opacity: .4
    }

    to {
        opacity: 1
    }
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
    .text {
        font-size: 11px
    }
}

@media screen and (max-width: 960px) {
    .home-hero-section {
        width: 100%;
        padding: 10px;
        flex-direction: column;
        gap: 10px;
    }

    /* .home-hero-description {
        display: none;
    } */

    .slideshow-container {
        width: 100%;
        min-height: 600px;
    }

    .tire-types-wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .tire-search-wrapper {
        /* position: absolute; */
        width: 100%;
        height: 250px;
        /* top: 50px; */
        /* left: 5px; */
        /* right: 5px; */
        padding-left: 5px;
        padding-right: 5px;
        /* padding: 10px; */
    }

    .tire-search {
        padding: 10px;
    }

    .hero-section-button {
        width: 150px;
    }

    .slideshow-content {
        flex-direction: column;
        justify-content: center;
        gap: 30px;
    }

    .slideshow-content-desc {
        display: none;
    }

    .slideshow-dot-content {
        left: 38%;
    }

    .tire-search-title {
        font-size: 14px;
    }
}