footer {
    background-color: #f7f7f7;
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin-top: 20px;
}

footer a {
    color: #000;
}

footer a:hover {
    color: #B33030;
}

.footer-top {
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;
    display: flex;
    border-bottom: #ccc solid 1px;
}

.footer-top-left {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.footer-top-left h3 {
    font-size: 30px;
}

.footer-top-left-email {
    width: 75%;
    display: flex;
}

.footer-top-left-email input {
    width: 100%;
    height: 40px;
    border: #ccc solid 1px;
    border-radius: 5px 0 0 5px;
    padding: 10px;
    border-right: none;
}

.footer-top-left-email button {
    width: 100%;
    height: 40px;
    border: #ccc solid 1px;
    border-radius: 0 5px 5px 0;
    background-color: #19282F;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}

.footer-top-right {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
}

.footer-top-right h3 {
    font-size: 30px;
}

.footer-top-right label {
    display: block;
    font-size: 16px;
    font-weight: bold;
}

.footer-top-right-btns {
    display: flex;
    gap: 10px;
    align-items: center;
}

.footer-top-right-contact {
    display: flex;
    gap: 50px;
    align-items: center;
}

.footer-content {
    margin: 0 auto;
    width: 100%;
    max-width: 1280px;
    display: flex;
    padding: 20px;
    gap: 20px;
    border-bottom: #ccc solid 1px;
    /* justify-content: space-between; */
}

.footer-content-link {
    flex: 1;
    display: flex;
    gap: 10px;
    /* justify-content: space-between; */
}

.footer-link {
    flex: 1;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    gap: 10px;
}

.footer-link h3 {
    font-weight: bold;
}

.footer-copyright {
    height: 50px;
    line-height: 50px;
}

.copyright-content {
    margin: 0 auto;
    width: 100%;
    max-width: 1150px;
    text-align: center;
}

.google-map {
    margin: 0;
    padding: 0;
    border: 1px solid #ccc;
    border-radius: 15px;
}



@media screen and (max-width: 960px) {

    .footer-top-left {
        width: 100%;
    }

    .google-map {
        width: 100%;
    }

    .footer-top {
        flex-direction: column;
    }

    .footer-content {
        flex-direction: column;
    }

    .copyright-content {
        font-size: 13px;
    }
}